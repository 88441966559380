import React, { createContext, useContext, useReducer, ReactNode } from "react";

// export const StateContext = createContext({});


export const StateContext = createContext({} as {
  state: any;
  dispatch: React.Dispatch<any>;
});

// https://github.com/KleeUT/hookstimator/blob/f3cb369600fea25d80a4c62701e7aa3728187bd0/src/estimate/EstimateStateProvider.tsx
export const GridsProvider = ({ reducer, initialState, children }: {
  reducer: any;
  initialState: any;
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
};

export const useGridsState = () => useContext(StateContext);
