import React from 'react';
import * as R from 'ramda';
import { Upload, Icon, message } from 'antd';
import { RcFile, UploadChangeParam, RcCustomRequestOptions } from "antd/lib/upload/interface";

import { Role } from '../interface';
import { useGridsState } from 'providers/GridsProvider';
const { Dragger } = Upload;



function chunkArray(myArray: string[], chunk_size: number) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk;
    myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}


function convertToGridsFormat(fileReaderResult: string[]) {
  let planTitle = fileReaderResult[0];
  // 中心點目標
  let centerMainGoal = fileReaderResult[1];

  // 八個子目標
  let sub8MainGoals = fileReaderResult.slice(2).filter((text) => {
    return /^\t/.test(text) && !/^\t\t/.test(text)
  });

  // 子九宮格的子目標
  let goalsOfSub8MainGoal = fileReaderResult.slice(2).filter((text, index) => {
    return /^\t\t/.test(text)
  });
  // 8個為同一組
  let goalsOfSub8MainGoalGroups = chunkArray(goalsOfSub8MainGoal, 8);
  // 插入 sub的主目標
  let eee = goalsOfSub8MainGoalGroups.map((item, index) => {
    return R.insert(4, sub8MainGoals[index], item);
  })

  // 插入主目標
  let centerGridsGoals = R.insert(4, centerMainGoal, sub8MainGoals);
  let goalList = R.insert(4, centerGridsGoals, eee);
  let info = goalList.map((content, index) => {
    let grids = content.map((x, index) => {
      return {
        id: index,
        content: x.trim(),
        role: index === 4 ? Role.Main : Role.Sub
      }
    });
    return {
      id: index,
      grids,
      role: index === 4 ? Role.Main : Role.Sub
    };
  });

  return { grids: info, planTitle };
}


const ImportFile: React.FC = () => {
  const { state, dispatch } = useGridsState();
  //kb
  const maxSize = 500;

  const draggerProps = {
    name: 'file',
    accept: "text/plain",
    onChange(info: UploadChangeParam) {
      // options：uploading, done, error, removed
      const { status } = info.file;
      if (status === 'uploading') {
        return false
      };

      if (status === 'error') {
        return message.error(`${info.file.name} 匯入失敗`);
      }

      if (status === 'done') {
        message.success(`${info.file.name} 匯入成功`);
        const { fileReaderResult } = info.file.response.data;
        const { grids, planTitle } = convertToGridsFormat(fileReaderResult);
        dispatch({ type: 'IMPORT_FILE_DATA', payload: { grids, planTitle } })
      }
    },
    beforeUpload(file: RcFile, FileList: RcFile[]) {
      if (file.size > maxSize * 1024) {
        message.error('檔案太大超過500kb');
        return false;
      }
      return true;
    },
    customRequest(options: RcCustomRequestOptions) {
      const { file, onSuccess } = options;
      let fileReader = new FileReader();
      fileReader.onloadend = (e: any) => {
        // e.target.result === fileReader.result
        const text = fileReader.result as string;
        const lines = text
          .replace(/[\r\n]/g, '\n')
          .replace(/[\r]/g, '\n')
          .replace(/[\n\n]/g, '\n')
          .split('\n')
        console.log(lines);
        onSuccess({ data: { status: 'done', fileReaderResult: lines } }, file);
      }
      fileReader.readAsText(file);
    }
  };
  return (
    <Dragger {...draggerProps}>
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">匯入檔案</p>
      <p>點擊或把檔案拖曳至此</p>
    </Dragger>
  );
}

export default ImportFile;
