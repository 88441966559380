

export enum Role {
  Main = 'main',
  Sub = 'sub',
}

export enum Mode {
  Part = 'part',
  Whole = 'whole',
}


export interface Target9x9 {
  id: number;
  grids: Target3x3[];
  // color: string;
  role: Role;
}


export interface Target3x3 {
  id: number;
  content: string | null;
  // color: string;
  role: Role;
}


export interface Action {
  type: string;
  payload: any;
}
