
import * as R from 'ramda';
import { saveAs } from 'file-saver';

import React from 'react';
import { Menu, Dropdown, Button, Icon, Modal, Typography } from 'antd';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { useGridsState } from 'providers/GridsProvider';
import { useAppState } from 'providers/AppProvider';
import { StyledPanel, StyledMinimapWrapper, ImportFileWrapper, StyledDropdownButton } from './PanelStyles';
import Minimap from './Minimap';
import { Target9x9, Target3x3, Role, Mode } from '../interface';
import { GRIDS_ID, GRID3x3_PREFIX, GRIDS_TITLE_ID, GRIDS_THEME_ID, MINIMAP_ID, DELETE_GRID_ID, IMPORT_EXPORT_ID, DOWNLOAD_SECTION__ID } from '../constant';
import ImportFile from './ImportFile';


const Panel: React.FC = () => {
  const { state: { grids, themes, mode, planTitle }, dispatch } = useGridsState();
  const { dispatch: AppDispatch } = useAppState();

  const imgWidth = 200;

  const themesMemu = Object.keys(themes).map((theme) => {
    return (
      <Menu.Item key={theme}>
        {themes[theme].title}
      </Menu.Item>
    )
  })
  const menu = (
    <Menu onClick={(event: any) => {
      dispatch({ type: 'SWITCH_THEME', payload: { themeType: event.key } })
    }}>
      {themesMemu}
    </Menu>
  );

  async function download() {
    AppDispatch({
      type: 'SET_IS_SPINNING',
      payload: { isSpinning: true }
    });
    // A4 size page of PDF
    // a4: 210mm * 297mm
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true
    });
    // 製作全景九宮格
    await makeWholeGridPdf(pdf);
    pdf.save("download.pdf");
    AppDispatch({
      type: 'SET_IS_SPINNING',
      payload: { isSpinning: false }
    });
  }

  async function makeWholeGridsImg() {
    const outerGrids: HTMLElement = document.getElementById(GRIDS_ID)!;
    const outerGridsCanvas = await html2canvas(outerGrids);
    return outerGridsCanvas;
  }

  async function makePlanTitleImg() {
    var temp: HTMLElement = document.createElement("div");
    temp.id = "temp";
    temp.setAttribute('style', 'width:100%;text-align:center;color: black;font-size:40px;z-index:-1;');

    temp.innerHTML = planTitle;
    document.body.appendChild(temp);
    const canvas = await html2canvas(document.getElementById('temp')!);
    document.getElementById('temp')!.remove();
    let imgData = canvas.toDataURL('image/png');
    return canvas;
  }

  async function makeWholeGridPdf(pdf: jsPDF) {
    // Step. 加入標題
    const planTitleCanvas = await makePlanTitleImg();
    let planTitleImg = planTitleCanvas.toDataURL('image/png');
    pdf.addImage(planTitleImg, 'PNG', (210 - imgWidth) / 2, 10, imgWidth, planTitleCanvas.height * imgWidth / planTitleCanvas.width);

    // Step. 加入九宮格全圖
    const wholeGridsCanvas = await makeWholeGridsImg();
    let wholeGridsImgData = wholeGridsCanvas.toDataURL('image/png');
    pdf.addImage(wholeGridsImgData, 'PNG', (210 - imgWidth) / 2, 30, imgWidth, wholeGridsCanvas.height * imgWidth / wholeGridsCanvas.width);
  }

  async function download2() {
    AppDispatch({
      type: 'SET_IS_SPINNING',
      payload: { isSpinning: true }
    });
    // A4 size page of PDF
    // a4: 210mm * 297mm
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true
    });


    // 製作全景九宮格
    await makeWholeGridPdf(pdf);

    // Step. 加入九宮格小圖
    const canvasListPs = grids.map((i: Target9x9) => `${GRID3x3_PREFIX}-${i.id}`)
      .map((partGrid: string) => document.getElementById(partGrid)!)
      .map((elem: HTMLElement) => html2canvas(elem));
    let canvasList: HTMLCanvasElement[] = await Promise.all(canvasListPs);
    canvasList.forEach((canvas: HTMLCanvasElement, index: number) => {
      let imgData = canvas.toDataURL('image/png');
      pdf.addPage('a4', 'p');
      const scaleImgWidth = imgWidth * 0.8;
      pdf.addImage(imgData, 'PNG', (210 - scaleImgWidth) / 2, 10, scaleImgWidth, canvas.height * scaleImgWidth / canvas.width);
    })
    pdf.save("download-t2.pdf");
    AppDispatch({
      type: 'SET_IS_SPINNING',
      payload: { isSpinning: false }
    });
  }


  function showPropsConfirm() {
    Modal.confirm({
      title: '確定要清掉嗎?',
      content: '清掉就沒有了',
      okText: '確定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        console.log('OK');
        dispatch({ type: 'RESET_GRIDS_DATA' });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <StyledPanel>
      <div>
        <Typography.Title id={GRIDS_TITLE_ID} ellipsis={{ rows: 3, expandable: false }} level={3} editable={{ onChange: (str: string) => dispatch({ type: 'UPDATE_PLAN_TITLE', payload: { planTitle: str } }) }}>{planTitle}</Typography.Title>
        <div id={GRIDS_THEME_ID}>
          <StyledDropdownButton overlay={menu} icon={<Icon type="down" />}>九宮格主題色彩</StyledDropdownButton>
        </div>
        <StyledMinimapWrapper id={MINIMAP_ID}>
          <Minimap />
          {mode === Mode.Part ?
          <Button style={{ width: '100%', marginTop: '10px' }} type="dashed" shape="round" icon="zoom-in" size="large" onClick={() => {
            dispatch({ type: 'SHOW_WHOLE' })
          }}>回到全景</Button> : <div style={{ height: '40px', marginTop: '10px' }}/>}
        </StyledMinimapWrapper>
      </div>
      <div id={DELETE_GRID_ID}>
        <Button style={{ width: '100%', marginTop: '10px' }} type="dashed" shape="round" icon="delete" size="large" onClick={showPropsConfirm}>清空重編</Button>
      </div>
      <div id={IMPORT_EXPORT_ID}>
        <ImportFileWrapper><ImportFile /></ImportFileWrapper>
        <Button style={{ width: '100%', marginTop: '10px' }} type="dashed" shape="round" icon="download" size="large" onClick={() => {
          // const pom = document.createElement('a');
          // const content = JSON.stringify(grids); //here we load our csv data
          // const blob = new Blob([content], { type: 'text/plain;charset=utf-8;' });
          // const url = URL.createObjectURL(blob);
          // pom.href = url;
          // pom.setAttribute('download', 'foo.txt');
          // pom.click();

          let content = "";
          content += `${planTitle}\n`;
          const centerGrids = grids.find((item: Target9x9) => item.role === Role.Main);
          const centerGoal = centerGrids.grids.find((item: Target3x3) => item.role === Role.Main).content;
          content += `${centerGoal}\n`;

          const excludeCenterGrids = grids.filter((item: Target9x9) => item.role !== Role.Main)
          excludeCenterGrids.forEach((grid: Target9x9) => {
            const lines = R.sortBy(R.prop('role'))(grid.grids).map(x => x.content);
            content += `\t${lines[0]}\n`;
            lines.slice(1).forEach((line) => {
              content += `\t\t${line}\n`;
            });
          });
          const filename = `${planTitle}.txt`;
          const blob = new Blob([content], {
            type: "text/plain;charset=utf-8"
          });
          saveAs(blob, filename);
        }}>匯出檔案</Button>
      </div>
      <div id={DOWNLOAD_SECTION__ID}>
        <Button style={{ width: '100%', marginTop: '10px' }} type="dashed" shape="round" icon="download" size="large" onClick={download}>下載單張PDF</Button>
        <Button style={{ width: '100%', marginTop: '10px' }} type="dashed" shape="round" icon="download" size="large" onClick={download2}>下載多張PDF</Button>
      </div>
    </StyledPanel>
  );
}

export default Panel;
