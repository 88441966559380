import React, { useState } from 'react';

import { Button, Spin } from 'antd';


import Grids from 'features/Grids';

import { AppProvider, useAppState } from 'providers/AppProvider';
import Sidebar from 'features/Sidebar';
import { StyledApp, SpinWrapper } from './AppStyles';




const App: React.FC = () => {
  const { state: { isSpinning } } = useAppState();


  // https://forms.gle/DR36VMgVeoFsn5gg7
  return (
    <StyledApp className="App">
      {isSpinning && <SpinWrapper><Spin spinning={isSpinning} size="large" /></SpinWrapper>}
      <div className="main"><Grids /></div>
      <div className="right-side"><Sidebar /></div>

      {/* <div className="main" />
      <div className="right-side" /> */}
    </StyledApp>
  );
}

export interface Action {
  type: string;
  payload: any;
}

export interface State {
  isSpinning: boolean;
}

const AppWithContext: React.FC = () => {
  const initialState: State = {
    isSpinning: false
  }
  function reducer(state: State, action: Action) {
    switch (action.type) {
      case 'SET_IS_SPINNING': {
        const { isSpinning } = action.payload;
        return {
          ...state,
          isSpinning
        };
      }
      default:
        throw new Error();
    }
  }
  return (
    <AppProvider initialState={initialState} reducer={reducer}>
      <App />
    </AppProvider>
  )
}




export default AppWithContext;
