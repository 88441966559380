import styled from 'styled-components';
import { darken, lighten } from 'polished';

interface Props {
  width: string;
  height: string;
  // color: string;
  size: string;
}

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items */
  min-height: 0;
  min-width: 0;
  width: ${(props: Props) => props.width};
  height: ${(props: Props) => props.height};
  font-size: ${(props: Props) => props.size};
  grid-gap: 0;
  margin: 0 auto;
`;



interface StyledGridProps {
  highlight: boolean;
  backgroundColor: string;
}

export const StyledGrid = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${(props: StyledGridProps) => props.highlight ? darken(0.2, props.backgroundColor): props.backgroundColor};
  border: ${(props: StyledGridProps) => props.highlight ? '2px solid black' : '1px solid black'};

  > .grid__panel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* position: relative;
    &:after {
      content: '10';
      left: 0;
      top: 0;
      position: absolute;
    } */

    > .view-mode {
      padding: 2px;
      white-space: pre-line;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: ${(props: { highlight: boolean }) => props.highlight ? 900 : 'normal'};
      color: ${(props: { highlight: boolean }) => props.highlight ? '#000' : 'initial'};
      width: 98%;
      height: 98%;
      overflow: hidden;
      /* -webkit-box會影響到html2canvas的輸出 */
      /* display: -webkit-box; */
      /* -webkit-box-orient: vertical; */
      /* -webkit-line-clamp: 4; */

      cursor: pointer;
      &:hover {
        border: 1px solid #40a9ff;
        border-radius: 10px;
      }
    }

  }
`;



