import React from 'react';
import { Mode } from '../interface';
import Grid3x3 from '../Grid3x3';
import { StyledGridsWrapper } from './Grid9x9Styles';
import { useGridsState } from "providers/GridsProvider";
import { GRIDS_ID } from "../constant";


const Grid9x9: React.FC = (props) => {
  const { state: { grids, mode, activeId } } = useGridsState();
  let translateStyle = 'translate(0,0)';

  interface TranslateStyle {
    [key: number]: string;
  }
  if (mode === Mode.Part) {
    const getTranslateStyle: TranslateStyle = {
      0: 'translate(0,0)',
      1: 'translate(-100%,0)',
      2: 'translate(-200%,0)',
      3: 'translate(0,-100%)',
      4: 'translate(-100%,-100%)',
      5: 'translate(-200%,-100%)',
      6: 'translate(0%,-200%)',
      7: 'translate(-100%,-200%)',
      8: 'translate(-200%,-200%)',
    };
    translateStyle = getTranslateStyle[activeId]
  }
  return (
    <div style={{ width: 'fit-content', margin: '0 auto' }}>
      <StyledGridsWrapper id={GRIDS_ID} translates={translateStyle}>
        {grids.map((item: any) => {
          return (
            <Grid3x3
              key={item.id}
              data={item.grids}
              fatherRole={item.role}
              fatherId={item.id}
            />
          )
        })}
      </StyledGridsWrapper>
    </div>
  );
}

export default Grid9x9;
