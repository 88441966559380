import styled from 'styled-components';

interface Props {
  translates: string;
}

export const StyledGridsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items */
  min-height: 0;
  min-width: 0;
  width: 822px;
  height: 822px;
  margin: 10px;
  transform: ${(props: Props) => props.translates};
  grid-gap: 1px;
  background-color: black;
  border: 1px solid black;
`;
