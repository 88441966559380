import styled from 'styled-components';

export const StyledAction = styled.div`
  height: 100%;
  /* border-radius: 15% 5% 15% 5%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid bisque; */
  /* background-color: #359c4d; */
  background-color: rgba(0,183,150,1);
  box-shadow: 0px -4px 3px 0px rgba(0,0,0,0.35);


  > .btn-groups {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border-radius: 11% 2%;
    > a > button {
      background-color: #FFF6EA;
      width: 130px;

      &:last-of-type {
        margin-bottom: 10px;
      }
      &:hover, &:focus {
        color: green;
        border-color: green;
      }
      /* --antd-wave-shadow-color: red !important; */
    }
  }

  .desc {
    padding: 5px;
  }
  .mail-to {
    color: inherit;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  @media(max-width: 1340px) {
    flex-direction: row;
    justify-content: space-around;
    > .btn-groups > a > button {
      margin: 0;
    }
  }
`;
