import React, { useState, Fragment } from 'react';
import { Typography, Icon, Modal, Button } from 'antd';


interface IProps {
  content: string | null;
  visible: boolean;
  onOk: (content: string) => void;
  onCancel: () => void;
}

const EditModal: React.FC<IProps> = (props) => {
  const { content, visible, onOk, onCancel } = props;
  let initialContent = content === null ? '' : content
  const [tmpContent, setTmpContent] = useState(initialContent);
  const disabled = tmpContent.length > 100;
  return (
    <Modal
      title="設定目標"
      visible={visible}
      onOk={() => onOk(tmpContent)}
      onCancel={onCancel}
      okText="OK"
      cancelText="取消"
      okButtonProps={{ disabled }}
    >
      <p>{disabled ? '勿超過100字' : `${tmpContent.length}/100`}</p>
      <textarea
        rows={4}
        cols={30}
        name="t1"
        onChange={(event) => {
          setTmpContent(event.target.value);
        }}
      >{tmpContent}</textarea>
    </Modal>
  );
}

export default EditModal;
