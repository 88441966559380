import styled from 'styled-components';

export const StyledApp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* padding: 20px; */


  > .main {
    flex: auto;
    height: 100%;
    padding: 20px;
  }

  > .right-side {
    margin-left: 15px;
    flex: 1;
    height: 100%;
    min-width: 200px;
    max-width: 200px;
  }


  @media(max-width: 1340px) {
    display: block;
    > .right-side {
      margin-left: 0px;
      margin-top: 15px;
      height: 180px;
      max-width: unset;
    }
  }
`;



export const SpinWrapper = styled.div`
  z-index: 9999;
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
