import React from 'react';
import { Target3x3, Role, Mode } from '../interface';
import { StyledWrapper } from './Grid3x3Styles';
import { useGridsState } from "providers/GridsProvider";
import Grid from './Grid';



interface IProps {
  data: Target3x3[];
  // color: string;
  fatherRole: Role;
  fatherId: number;
}

const Grid3x3: React.FC<IProps> = (props) => {
  const { data, fatherRole, fatherId } = props;
  const { state, dispatch } = useGridsState();
  function onChange(content: string, id: number, role: Role) {
    dispatch({ type: 'UPDATE_VALUE_BY_ID', payload: { content, fatherId, id, fatherRole, role } })
  }
  const width = state.mode === Mode.Part ? '822px' : '100%';
  const height = state.mode === Mode.Part ? '822px' : '100%';
  const size = state.mode === Mode.Part ? '20px' : '14px';
  return (
    <StyledWrapper
      width={width}
      height={height}
      size={size}
      id={`grid3x3-${fatherId}`}
    >
      {data.map((item) => {
        return (
          <Grid
            backgroundColor={state.themes[state.themeType].getColor(fatherId, item.role, fatherRole, item.id)}
            key={item.id}
            content={item.content}
            id={item.id}
            role={item.role}
            onOk={(content: string, id: number, role: Role) => {
              onChange(content, id, role)
            }}
          />
        )
      })}
    </StyledWrapper>
  );
}

export default Grid3x3;
