import React, { useEffect } from 'react';

import { StyledWrapper } from './GridsStyles';
import Panel from './Panel';
import Grid9x9 from './Grid9x9';
import { useGridsState } from "providers/GridsProvider";
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';



const Grids: React.FC = () => {
  const { state } = useGridsState();
  const { grids, planTitle } = state;
  const [gridsInfoLs, setGridsInfoLs] = useStateWithLocalStorage('gridsInfo');
  const [planTitleLs, setPlanTitleLs] = useStateWithLocalStorage('planTitle');

  // TODO: 不確定這樣好不好
  useEffect(() => {
    setGridsInfoLs(JSON.stringify(grids));
    setPlanTitleLs(planTitle);
  }, [JSON.stringify(grids), planTitle]);


  return (
    <StyledWrapper>
      <div className="grid99-wrapper">
        <div className="grids-left-zone" id="grids-left-zone">
          <Grid9x9 />
        </div>
        <div className="grids-right-zone">
          <Panel />
        </div>
      </div>
    </StyledWrapper>
  );
}

export default Grids;
