import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

import { useGridsState } from 'providers/GridsProvider';
import { LEFT_ZONE } from '../constant';

export const StyledMinimap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items */
  min-height: 0;
  min-width: 0;
  width: 110px;
  height: 110px;
  grid-gap: 0;

  > div {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      transform: scale(1.2);
      background-color: red;
    }
  }
`;

const Minimap: React.FC = () => {
  const { state, dispatch } = useGridsState();
  const { activeId } = state;
  return (
    <StyledMinimap>
      {Array.from(Array(9).keys()).map((item: number) => {
        return (
          <div
            key={item}
            onClick={() => {
              document.getElementById(LEFT_ZONE)!.scrollTop = 0;
              document.getElementById(LEFT_ZONE)!.scrollLeft = 0;
              dispatch({ type: 'SHOW_PART', payload: { activeId: item } })
            }}
            style={{ backgroundColor: item === activeId ? 'yellow' : 'white' }}
          >
            <div>編輯<Icon theme="outlined" type="zoom-in" /></div>
          </div>
        )
      })}
    </StyledMinimap>
  );
}

export default Minimap;
