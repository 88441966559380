import React, { useState } from 'react'
import Tour from 'reactour';

import { GRIDS_ID, GRIDS_THEME_ID, GRIDS_TITLE_ID, MINIMAP_ID, DELETE_GRID_ID, IMPORT_EXPORT_ID, DOWNLOAD_SECTION__ID } from '../Grids/constant';

const steps = [
  {
    selector: `#${GRIDS_ID}`,
    content: '在格子上點擊就可以編輯內容',
  },
  {
    selector: `#${GRIDS_TITLE_ID}`,
    content: '可以給這個計畫取個名字',
  },
  {
    selector: `#${GRIDS_THEME_ID}`,
    content: '可以選擇顏色主題',
  },
  {
    selector: `#${MINIMAP_ID}`,
    content: '點擊小方格可以局部編輯',
  },
  {
    selector: `#${DELETE_GRID_ID}`,
    content: '可以清空再編一個',
  },
  {
    selector: `#${IMPORT_EXPORT_ID}`,
    content: '編輯完成可以匯出文字檔，匯出的檔案可以再匯入',
  },
  {
    selector: `#${DOWNLOAD_SECTION__ID}`,
    content: () => {
      return (
        <div>
          可以下載pdf<br/>
          單張模式是大九宮格計劃表<br/>
          多張模式會再加入局部小九宮格<br/>
        </div>

      )
    }
  },
]


interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProductTour: React.FC<IProps> = (props) => {
  return (
    <div>
      <Tour
        steps={steps}
        isOpen={props.isOpen}
        onRequestClose={() => {
          props.onClose();
        }}
      />
    </div>
  );
}

export default ProductTour;
