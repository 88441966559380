import styled from 'styled-components';


export const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;

    > .grid99-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: auto;
      padding: 5px;


      > .grids-left-zone {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
        flex: 5;
        min-width: 900px;
        height: 100%;
        overflow: auto;
      }

      > .grids-right-zone {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
        margin-left: 10px;
        flex: 1;
        overflow: auto;
        height: 100%;
        padding: 15px;
      }


      @media(max-width: 1280px) {
        flex-direction: column;
        width: 100%;
        > .grids-left-zone {
          flex:2;
          width: 100%;
          min-width: unset;
        }
        > .grids-right-zone {
          flex:1;
          width: 100%;
          margin-left: 0px;
          margin-top: 10px;
        }
      }
  }
`;
