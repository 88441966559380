import React, { useState } from 'react';

import { Button } from 'antd';


import ProductTour from 'features/ProductTour';
import { StyledAction } from './SidebarStyles';




const Sidebar: React.FC = () => {
  const [openProductTour, setOpenProductTour] = useState(false);
  return (
    <StyledAction className="action">
      <div className="btn-groups">
      <p className="desc">受到大谷翔平的九宮格吸引而看了"學會「曼陀羅計劃表」這本書"。因為苦擾每次都要手畫表格所以做了這個side project，分享給想用九宮格做計畫的朋友們。 <br /></p>
        <a href="https://forms.gle/DR36VMgVeoFsn5gg7" target="_blank"><Button type="dashed" shape="round" icon="exception" size="large">問題回報</Button></a>
        <a href="https://forms.gle/4wbJy7xbq6xy9JqN9" target="_blank"><Button type="dashed" shape="round" icon="star" size="large">評分</Button></a>
      </div>
      <div className="footer">

        <Button icon="question-circle" onClick={() => setOpenProductTour(true)}>入門引導</Button>
        <p>
          <br />九宮格模板|快速開啟新計畫 <br />
          <a className="mail-to" href="https://www.winsomeyang.com" target="_blank">@winsome在學</a>
        </p>
        <ProductTour isOpen={openProductTour} onClose={() => setOpenProductTour(false)} />
      </div>
    </StyledAction>
  );
}

export default Sidebar;
