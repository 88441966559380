import * as R from 'ramda';
import React from 'react';

import { GridsProvider } from "providers/GridsProvider";
import { Target9x9, Target3x3, Action, Role, Mode } from './interface';
import Grids from './Grids';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';

const color = ['#B3CCC9', '#FFEAEA', '#FFFBED', '#E7F0F7', '#FFFFFF', '#E5DFD9', '#F8F3EC', '#D1D9D3', '#E0DBEA', '#F8DFE2'];
const themes = {
  light: {
    title: '白色',
    getColor: (fatherId: number, role: Role, fatherRole: Role, id: number) => {
      return Array.from(Array(9).keys()).map(x => '#FFFFFF')[fatherId];
    }
  },
  multicolor: {
    title: '彩色',
    getColor: (fatherId: number, role: Role, fatherRole: Role, id: number) => {
      return color[fatherId];
    }
  },
  multicolorCenter: {
    title: '簡單彩色',
    getColor: (fatherId: number, role: Role, fatherRole: Role, id: number) => {
      if (fatherRole === Role.Main) {
        return color[id]
      }
      if (role === Role.Main) {
        return color[fatherId]
      }
      return '#FFFFFF'
    }
  },
};

const initialState3x3 = Array.from(Array(9).keys()).map((x): Target3x3 => ({
  id: x,
  content: '',
  role: x === 4 ? Role.Main : Role.Sub
}));


const alterContentById = R.curry((id, value, items) => R.map(
  R.when(R.propEq('id', id), R.assoc('content', value)),
  items
));

const alterGridsById = R.curry((id, value, items) => R.map(
  R.when(R.propEq('id', id), R.assoc('grids', value)),
  items
));

const initialGrids99: Target9x9[] = Array.from(Array(9).keys()).map((x): Target9x9 => {
  return {
    id: x,
    grids: initialState3x3,
    role: x === 4 ? Role.Main : Role.Sub
  };
});



const Grid99Wrapper: React.FC = () => {
  const [gridsInfoLs, setGridsInfoLs] = useStateWithLocalStorage('gridsInfo');
  const [planTitleLs, setPlanTitleLs] = useStateWithLocalStorage('planTitle');

  function initialState(reset = false) {
    return {
      grids: gridsInfoLs && !reset ? JSON.parse(gridsInfoLs) : initialGrids99,
      themes,
      themeType: 'multicolor',
      mode: Mode.Whole,
      activeId: -1,
      planTitle: planTitleLs && !reset ? planTitleLs : `標題-${Math.random().toString(36).substring(8)}`,
    }
  };

  function reducer(state: { grids: Target9x9[], themes: string }, action: Action) {
    switch (action.type) {
      case 'UPDATE_VALUE_BY_ID': {
        const { content, id, role, fatherRole, fatherId } = action.payload;

        // step1. 修改內容
        let updatedState: Target9x9[] = state.grids.map(items => {
          if (items.id !== fatherId) {
            return items;
          }
          return {
            ...items,
            grids: alterContentById(id, content, items.grids)
          }
        });


        // step2 某些位置的內容要同步修改
        if (role === Role.Main && fatherRole === Role.Sub) {
          const gridMain: Target9x9 = updatedState.find(x => x.role === Role.Main)!;
          // 把fatherId的content換成gridMain.grids
          let updatedGridMain = alterContentById(fatherId, content, gridMain.grids);
          updatedState = R.map(
            R.when(R.propEq('role', Role.Main), R.assoc('grids', updatedGridMain)),
            updatedState
          )
        }
        if (role === Role.Sub && fatherRole === Role.Main) {
          const gridSub: Target9x9 = updatedState.find(x => x.id === id)!;
          let updatedGridSub = R.map(
            R.when(R.propEq('role', Role.Main), R.assoc('content', content)),
            gridSub.grids
          );
          updatedState = alterGridsById(id, updatedGridSub, updatedState)
        }

        // FIXME
        // console.log(JSON.stringify(updatedState))
        // setGridsInfoLs(JSON.stringify(updatedState));
        return {
          ...state,
          grids: updatedState,
        };
      }

      case 'IMPORT_FILE_DATA': {
        const { grids, planTitle }: { grids: Target9x9[], planTitle: string } = action.payload;
        // setGridsInfoLs(JSON.stringify(grids));
        return {
          ...state,
          grids,
          planTitle
        };
      }

      case 'SWITCH_THEME': {
        const { themeType }: { themeType: string } = action.payload;
        return {
          ...state,
          themeType
        }
      }
      case 'SHOW_PART': {
        const { activeId }: { activeId: number } = action.payload;
        return {
          ...state,
          mode: Mode.Part,
          activeId
        }
      }
      case 'SHOW_WHOLE': {
        return {
          ...state,
          mode: Mode.Whole,
          activeId: -1
        }
      }
      case 'UPDATE_PLAN_TITLE': {
        const { planTitle }: { planTitle: string } = action.payload;
        return {
          ...state,
          planTitle
        }
      }
      case 'RESET_GRIDS_DATA': {
        return initialState(true);
      }
      default:
        throw new Error();
    }
  }

  return (
    <GridsProvider initialState={initialState(false)} reducer={reducer}>
      <Grids />
    </GridsProvider>
  );
}

export default Grid99Wrapper;
