import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
// import { string } from 'prop-types';

// const useStateWithLocalStorage = (localStorageKey: string) => {
//   const [value, setValue] = React.useState<string>(
//     localStorage.getItem(localStorageKey) || ''
//   );
//   React.useEffect(() => {
//     localStorage.setItem(localStorageKey, value);
//   }, [value]);
//   return [value, setValue];
// };


// export default useStateWithLocalStorage;


// const useStateWithLocalStorage = <S>(
//   key: string,
//   initialValue?: S
// ): [S, Dispatch<SetStateAction<S>>] => {
//   const [item, setInnerValue] = useState<S>(() => {
//     const valueItem = window.localStorage.getItem(key)
//       return valueItem ? JSON.parse(valueItem) : initialValue
//     })

//   const setValue = (value: SetStateAction<S>): SetStateAction<S> => {
//     const valueToStore = value instanceof Function ? value(item) : value
//         setInnerValue(valueToStore)
//     if (valueToStore) {
//           window.localStorage.setItem(key, JSON.stringify(valueToStore))
//         } else {
//           window.localStorage.removeItem(key)
//         }
//         return value
//       }

//       return [item, setValue]
//     }

// export default useStateWithLocalStorage


const useStateWithLocalStorage = (key: string) => {
  const [value, setValue] = useState<any>(localStorage.getItem(key) || "");

  useEffect(() => {
    localStorage.setItem(key, value);
  }, [value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
