import styled from 'styled-components';
import { Dropdown } from 'antd';

export const StyledPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


export const StyledMinimapWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const ImportFileWrapper = styled.div`
  height: 150px;
`;


export const StyledDropdownButton = styled(Dropdown.Button)`
  display: flex;

  >.ant-btn:nth-of-type(1) {
    flex: auto;
  }
  >.ant-btn:nth-of-type(2) {
    flex: 0;
  }
`;

