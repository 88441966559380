import React, { useState, Fragment } from 'react';
import { Role } from '../interface';
import { StyledGrid } from './Grid3x3Styles';
import EditModal from './EditModal';



interface IProps {
  id: number;
  role: Role;
  content: string | null;
  backgroundColor: string;
  onOk: (content: string, id: number, role: Role) => void;
}

const Grid: React.FC<IProps> = (props) => {
  const { id, content, role, onOk, backgroundColor } = props;
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const highlight = role === Role.Main;
  return (
    <StyledGrid key={id} highlight={highlight} backgroundColor={backgroundColor}>
      <div className="grid__panel" onClick={() => { setIsEditModalVisible(true); }}>
        <div className="view-mode" title="點擊可編輯">{content}</div>
      </div>
      {isEditModalVisible && <EditModal
        content={content}
        visible={isEditModalVisible}
        onOk={(updatedContent) => {
          setIsEditModalVisible(false);
          onOk(updatedContent, id, role)
        }}
        onCancel={() => { setIsEditModalVisible(false) }}
      />}
    </StyledGrid>
  );
}

export default Grid;
